import React, { Component } from "react";
import './OtherObject.scss';
import {FormattedMessage, IntlShape, injectIntl, defineMessages } from 'react-intl';
//import {injectIntl} from 'react-intl';


const messages = defineMessages({
    bye: {
        id: 'otherObject-bye',
        defaultMessage: 'Bye bye!',
    },
});

class OtherObject extends Component {


    constructor(props) {
        super(props);
    }

    render() {
        //console.log(this.props);

        return (
            <div className={"OtherObject"}>
                <FormattedMessage id="otherObject-hello" defaultMessage={"Hello"} description={"Hello message on Other Object"}/>
                <h1>{this.props.intl.formatMessage(messages.bye)}</h1>
            </div>
        );
        //
    }
}

export default injectIntl(OtherObject);