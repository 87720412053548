import React, {Component} from 'react';
import {Switch, NavLink, matchPath, withRouter} from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import Routes from './routes';
import {loadData} from "../providers/data-manager/loadData";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {IntlProvider} from "react-intl";
import ReactGA from 'react-ga';

//console.log(Twitter);


class App extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
    constructor(props) {
        super(props);
        //console.log("App Constructor");
        /*if(props.data) {
            console.log("Props Data!");
            this.state = {
                data: props.data,
            };
            this.state.data.ssr = true;
        } else {
            console.log("No Props Data");
            this.state = {
                data: {
                    firebase: null,
                    umbraco: null,
                    language: 'en',
                    showMap: false,
                    ssr: false
                },

                moveLeft: true,
            };
        }*/
        //console.log("WebappData: ",props.webappData);
        this.data = {};
        if(props.data) {
            //console.log("Props Data!");
            this.data = props.data;
            this.data.ssr = true;
            this.innerHeight = null;
        } else {
            //console.log("No Props Data");
            if(window.__ROUTE_DATA__) {
                //console.log(window.__ROUTE_DATA__);
                this.data = window.__ROUTE_DATA__ ? window.__ROUTE_DATA__ : {};
                this.data.ssr = false;
                //console.log("Setting this.data to window route data", this.data);
                delete window.__ROUTE_DATA__;
            }
            else {
                //console.log("No ROUTE_DATA");
                loadData('todos').then(data => {
                    //console.log("DATA: ",data);
                    this.data = data;
                });
            }

            //console.log("History: ", window.history);
            /*this.innerHeight = window.innerHeight;
            console.log("Setting innerHeight to window",  this.innerHeight);

            window.onresize = function() { console.log("Resized! ", window.innerHeight); }*/
        }
        if(props.webappData) {
            this.webappData = props.webappData;
        }
        else {
            if(window.__WEBAPP_DATA__) {
                //console.log(window.__ROUTE_DATA__);
                this.webappData = window.__WEBAPP_DATA__ ? window.__WEBAPP_DATA__ : {};
                //console.log("Setting this.data to window route data", this.data);
                delete window.__WEBAPP_DATA__;
            }
        }
        //console.log("WebappData: ",this.webappData);
        //console.log(this.data);

        ReactGA.initialize('UA-124908978-1', {
            debug: true,
        });
        ReactGA.set({ anonymizeIp: true });
    }

    componentDidMount() {
        //console.log("App Did Mount!");
        /*setTimeout(() => {
            if (window.__ROUTE_DATA__) {
                //console.log("We got ROUTE_DATA!");
                console.log("DATA: ",window.__ROUTE_DATA__);
                this.setState({
                    data: window.__ROUTE_DATA__
                });
                delete window.__ROUTE_DATA__;
            } else {
                console.log("No ROUTE_DATA");
                loadData('todos').then(data => {
                    //console.log("DATA: ",data);
                    this.setState({
                        data
                    });
                });
            }
        }, 0);*/

        //let RL = require('react-leaflet')
        //console.log(RL);
        //Map = RL.Map
        //TileLayer = RL.TileLayer
        /*setTimeout(function() {
            window.scrollTo(0, 200);
        }, 500);*/
        //console.log("Heights: ", window.innerHeight, window.screen.availHeight);
    }

    render() {
        //console.log("App Render");
        //console.log(this.props);
        /*const locale = this.state.data.language ? this.state.data.language.split(/[-_]/)[0] : "en";
        if(this.state.data.firebase) {
            console.log("Thing: ",this.state.data.firebase.default.LANGUAGES[locale].WebAppText['otherObject-hello']);
        }*/
        //console.log("ServerSideRender?: ",this.state.ssr);


        return (
        //<IntlProvider locale={locale} messages={this.state.data.firebase ? this.state.data.firebase.default.LANGUAGES[locale].WebAppText : null}>
            <div className={"App"}>
                <Helmet>
                    <title>OnSpotStory WebApp</title>
                    {this.webappData && this.webappData.WebAppConfig ? <link href={this.webappData.WebAppConfig.CSS} rel="stylesheet"/> :""}
                </Helmet>
                {/*<ul>
                    <li>
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/aland/">Application Level</NavLink>
                    </li>
                    <li>
                        <NavLink to="/aland/sv/">Application Level with Language</NavLink>
                    </li>
                    <li>
                        <NavLink to="/aland/sv/15949/">Walk</NavLink>
                    </li>
                    <li>
                        <NavLink to="/aland/sv/15949/15953">Walk with Location Opened</NavLink>
                    </li>
                </ul>*/}
                {/*<Twitter style={{width:"128px",height:"128px"}} className={"twitter"}/>*/}


                {/*<div className={"Content"}>
                    {this.state.showMap ? (
                        <Map
                            markers={markers}
                            moveLeft={this.state.moveLeft}
                        />
                    ) : <div>Map requires javascript enabled</div>}

                    */}
                    <Switch>
                        {renderRoutes(Routes, {data: this.data, webappData: this.webappData})}
                    </Switch>
                {/*</div>*/}



                {/*<Footer/>*/}
            </div>
       //</IntlProvider>
        );
    }
}


export default withRouter(App);
