import React, {Component} from 'react';
import './ImagePlayer.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";
import Fullscreen from '../../pages/Fullscreen/Fullscreen';

import {ReactComponent as LeftIcon} from '../../assets/Icons/left.svg';
import {ReactComponent as RightIcon} from '../../assets/Icons/right.svg';
import {ReactComponent as FullscreenIcon} from '../../assets/Icons/full_screen-01.svg';

class ImagePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentpic: 0,
            lastpic: 0,
            render: 0,
            fullscreen:false
        }
    }

    componentDidMount() {
    }

    goRight() {
        let currentpic = this.state.currentpic;
        let render = this.state.render;
        let lastpic = currentpic;
        if(currentpic < (this.props.images.length-1))
        {
            currentpic++;
        }
        render++;
        console.log("Going Right: ", currentpic, lastpic);
        this.setState({currentpic:currentpic, lastpic: lastpic, render: render}, null);
    }
    goLeft() {
        let currentpic = this.state.currentpic;
        let render = this.state.render;
        let lastpic = currentpic;
        if(currentpic > 0)
        {
            currentpic--;
        }
        console.log("Going Left: ", currentpic, lastpic);
        render++;
        this.setState({currentpic:currentpic, lastpic: lastpic, render: render}, null);
    }
    gotoImage(i) {
        let currentpic = this.state.currentpic;
        let lastpic = currentpic;
        let render = this.state.render;
        currentpic = i;
        render++;
        this.setState({currentpic:currentpic,lastpic:lastpic, render:render}, null);
    }
    openFullscreen() {
        console.log("CurrentPic: ", this.state.currentpic);
        this.props.openFullscreen(this.props.images, this.props.captions, this.state.currentpic);
        this.setState({fullscreen:true});
        //this.props.setFullScreenContent(this.props.images[this.state.currentpic]);
        //this.props.openFullscreenContent();
    }

    componentDidUpdate(prevProps, prevState) {
        let images = this.props.images;
        if(!ImagePlayer.arraysEqual(images, this.state.images))
        {
            this.setState({currentpic: 0, lastpic: 0, render: 0, images: images}, null);
        }
    }

    render() {
        let images = this.props.images;
        //console.log("Images: ",images);
        let imageNodes = [];
        let dotNodes = [];
        let dotnodeClass = "";
        let leftammount;
        for(let i = 0; i < images.length; i++) {
            //let caption = this.props.captions[i];
            leftammount = ((i*100)-(this.state.currentpic*100)) + "%";
            imageNodes.push(<div className={"Img"+(this.state.lastpic > this.state.currentpic ? " swoshRight":" swoshLeft")} style={{backgroundImage: "url("+images[i]+")", left: leftammount}}  key={"img"+i+(this.state.render)}/>);
            dotNodes.push(<div className={"dotNode"+(i===this.state.currentpic?" current":"")} key={"dot"+i} onClick={this.gotoImage.bind(this, i)}/>);
        }
        let caption = this.props.captions[this.state.currentpic] ? this.props.captions[this.state.currentpic].caption : null;
        return (
            <div className={"ImagePlayer"}>
                <div className="ScrollThingi">
                    <div className="Images">
                        {imageNodes}
                    </div>
                </div>
                <div className={"dotNodes" + (images.length > 1 ? " active": "")}>
                    {dotNodes}
                </div>
                {this.state.currentpic > 0 ? <LeftIcon onClick={this.goLeft.bind(this)} className={"Left"}/> : ""}
                {this.state.currentpic < (images.length-1)?<RightIcon onClick={this.goRight.bind(this)} className={"Right"}/>:""}
                {caption && caption !== "" ? <div className={"caption"}>{caption}</div>:""}
                <div className="fullscreen_button" onClick={this.openFullscreen.bind(this)}>
                    <FullscreenIcon className={"fullscreen_button"}/>
                </div>
                {this.state.fullscreen ? <Fullscreen
                    image={this.props.images[this.state.currentpic]}
                    caption={this.props.captions[this.state.currentpic] ? this.props.captions[this.state.currentpic].caption : null}
                    goLeft={this.goLeft.bind(this)}
                    goRight={this.goRight.bind(this)}
                /> : ""}
            </div>

        );
    }

    static arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length != b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }
}
/*
<div className="imagePlayer">
					<div className="scrollthingi">
						<div className="images">
							{imageNodes}
						</div>
					</div>
					<div className={dotnodeclass}>
						{dotnodes}
					</div>
					{lefticon}
					{righticon}
					{caption}
					<div className="fullscreen_button" onClick={this.openFullscreen}>
						<Icon name={fullscreen}/>
					</div>

				</div>
 */


export default ImagePlayer;
