import Root from './Root';
import AppList from "../pages/AppList/AppList";
import AppInstance from '../pages/AppInstance/AppInstance';
import Categories from '../pages/Categories/Categories';
import Walk from '../pages/Walk/Walk';
import Events from '../pages/Events/Events';
import NotFound from '../pages/NotFound/NotFound';
import LanguageSelect from "../pages/LanguageSelect/LanguageSelect";

const Routes = [
    {
        component: Root,
        routes: [
            {
                path: '/',
                exact: true,
                component: AppList
            },
            {
                path: '/:appid',
                exact: true,
                component: AppInstance
            },
            {
                path: '/:appid/events/',
                exact: true,
                component: Events
            },
            {
                path: '/:appid/events/:Event',
                exact: true,
                component: Events
            },
            {
                path: '/:appid/:language',
                exact: true,
                component: AppInstance
            },
            {
                path: '/:appid/:language/categories/',
                exact: true,
                component: Categories
            },
            {
                path: '/:appid/:language/categories/*',
                exact: false,
                component: Categories
            },
            {
                path: '/:appid/:language/:walkid',
                exact: true,
                component: Walk
            },
            {
                path: '/:appid/:language/:walkid/:location',
                exact: true,
                component: Walk
            },
            {
                component: NotFound
            }
        ]
    }
];

export default Routes;
