import React, {Component} from 'react';
import './VideoPlayer.scss';
import {IntlProvider} from "react-intl";
import ReactGA from "react-ga";

class ImagePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log("Videoplayer did mount: ", this.props.video);
    }

    play() {
        ReactGA.event({
            category: 'Video',
            action: 'VideoPlay'
        });
    }
    stop() {
        ReactGA.event({
            category: 'Video',
            action: 'VideoStopped'
        });
    }
    pause() {
        ReactGA.event({
            category: 'Video',
            action: 'VideoPaused'
        });
    }

    render() {
        return (
            <div className={"VideoPlayer"}>
                <video src={this.props.video} poster={this.props.videoPoster} controls onPlay={this.play.bind(this)} onPause={this.pause.bind(this)} onEnded={this.stop.bind(this)}/>
            </div>

        );
    }

}
/*
<div className="imagePlayer">
					<div className="scrollthingi">
						<div className="images">
							{imageNodes}
						</div>
					</div>
					<div className={dotnodeclass}>
						{dotnodes}
					</div>
					{lefticon}
					{righticon}
					{caption}
					<div className="fullscreen_button" onClick={this.openFullscreen}>
						<Icon name={fullscreen}/>
					</div>

				</div>
 */


export default ImagePlayer;
