import React, { Component } from "react";
import {Helmet} from "react-helmet";
import "./AppInstance.scss";
import Titlebar from '../../components/Titlebar/Titlebar';
import ListItem from '../../components/ListItem/ListItem';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import SwapButton from '../../components/SwapButton/SwapButton';
import ReactGA from "react-ga";

const TAG = "AppInstance";

class AppInstance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: 0
        }
    }

    componentDidMount() {
        ReactGA.pageview('AppInstance');
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);

        //console.log("AppInstance did Mount");

        //this.props.setActive(this.props.match.params.appid, this.props.match.params.language, this.props.match.params.walkid, this.props.match.params.location);
        console.log("Do we have the data we need?");
        if(!this.props.data.umbraco) {
            console.log("Umbraco Data needed, download it now!");
            this.props.downloadUmbracoData();
        }
        else {
            console.log("Umbraco DATA:", this.props.data.umbraco.LanguageCode, this.props.match.params.language)
            if(this.props.data.umbraco.Id !== this.props.match.params.appid) {
                // Different app, download UmbracoData
                this.props.downloadUmbracoData();
            }
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params) {
            //console.log(prevProps.match.params, this.props.match.params);
            //this.props.setActive(this.props.match.params.appid, this.props.match.params.language, this.props.match.params.walkid, this.props.match.params.location);
            if(prevProps.match.params.language !== this.props.match.params.language) {
                // New language!
                if(this.props.data.umbraco && this.props.data.umbraco.LanguageCode !== this.props.match.params.language) {
                    //console.log("Different language of Umbraco Data needed, download it now!");
                    this.props.downloadUmbracoData();
                }
            }
        }
    }

    setTab(i) {
        if(i === 2) {
            this.props.history.push("categories/");
        }
        this.setState({tab: i},null);
    }

    strip_html_tags(str)
    {
        if ((str===null) || (str==='') || typeof str == "undefined")
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    render() {
        let languages = [];
        let default_lang;
        let walks = [];
        const serverLang = this.props.routeData ? this.props.routeData.params.language : this.props.data.language;
        let externalLinks = [];

        if(this.props.data && this.props.data.firebase) {
            let firebase_app = this.props.data.firebase[this.props.match.params.appid];
            //console.log(this.props.data.firebase[this.props.match.params.appid] ? this.props.data.firebase[this.props.match.params.appid] : "");

            if(firebase_app) {
                firebase_app.APP_LANGUAGE.supported = (typeof firebase_app.APP_LANGUAGE.supported === "object" ? firebase_app.APP_LANGUAGE.supported : JSON.parse(firebase_app.APP_LANGUAGE.supported));
                if(this.props.data.firebase) {
                    for(let l in firebase_app.APP_LANGUAGE.supported) {
                        if(firebase_app.APP_LANGUAGE.supported.hasOwnProperty(l)) {
                            let lang = firebase_app.APP_LANGUAGE.supported[l];
                            //languages.push(<li key={"lang_"+l} style={{fontWeight: (firebase_app.APP_LANGUAGE.default === lang ? "bold": "normal")}}><NavLink to={(this.props.match.params.language ? "../"+lang+"/" : lang+"/")}>{lang}</NavLink></li>)
                            languages.push(lang);
                            if(firebase_app.APP_LANGUAGE.default === lang) {
                                default_lang = lang;
                            }
                        }
                    }
                }
            }


            if(this.props.data.umbraco) {
                for(let w in this.props.data.umbraco.Walks) {
                    if(this.props.data.umbraco.Walks.hasOwnProperty(w)) {
                        let walk = this.props.data.umbraco.Walks[w];
                        if(walk.Languages.includes(this.props.match.params.language)) {
                            //walks.push(<li key={"walk_"+w} title={walk.Description}><NavLink to={walk.Id+"/"}>{walk.Name}</NavLink></li>)
                            walks.push(<ListItem
                                key={"li_"+w}
                                image={walk.DescriptionImages.length > 0 ? walk.DescriptionImages[0] : ""}
                                title={walk.Name}
                                subtitle={walk.Locations.length + " LOCATIONS"}
                                navlink={walk.Id+"/"}
                            />)
                        }
                    }
                }

                for(let l in this.props.data.umbraco.MenuItems) {
                    if(this.props.data.umbraco.MenuItems.hasOwnProperty(l)) {
                        let link = this.props.data.umbraco.MenuItems[l];

                        externalLinks.push(
                            <li key={"link_"+l}>
                                <a target="_blank" href={link.path}>{link.title}</a>
                            </li>
                        )
                    }
                }
            }
        }
        //console.log(this.props.data.umbraco);

        let title = (this.props.data.umbraco ? this.props.data.umbraco.Name : "App");
        return <div className={"AppInstance"}>
            <Helmet>
                <title>{title}</title>
                {(this.props.data.umbraco && this.props.data.umbraco.AboutImage ? <meta property="og:image" content={(this.props.data.umbraco.AboutImage)}/> : "")}
                {(this.props.data.umbraco && this.props.data.umbraco.MenuDescriptionNewVersion ? <meta property="og:description" content={this.strip_html_tags(this.props.data.umbraco.MenuDescriptionNewVersion[serverLang])}/>:"")}
                <meta property="og:title" content={title}/>
            </Helmet>

            <div className={"List"}>
                <Titlebar
                    title={title}
                    setTab={this.setTab.bind(this)} tabs={["Walks","Info","Categories"]}
                    tab={this.state.tab}
                    back={(this.props.webappData.Id ? "": "../")}
                    webappData={this.props.webappData}
                    swapped={this.props.swapped}
                />
                <div className={"Listcontent"}>
                    {/*<h1>APP INSTANCE: {this.props.match.params.appid ? this.props.match.params.appid : 0}</h1>
                        <h2>Language: {this.props.match.params.language ? this.props.match.params.language : 0}</h2>
                        <h3>CMS Guide Number: {this.props.data.firebase ? (this.props.data.firebase[this.props.match.params.appid] ? this.props.data.firebase[this.props.match.params.appid].APP_SETTINGS.CMS_GUIDE_NUMBER : "No app with that ID") : ""}</h3>*/}
                    <div className={"Tab"+(this.state.tab===0?" Active":"")}>
                        {walks}
                    </div>
                    <div className={"Tab"+(this.state.tab===1?" Active":"")}>
                        {serverLang && this.props.data.umbraco  ?
                            <div className="Info" >
                                {this.props.data.umbraco.AboutImage ?
                                    <div className={"AboutImage"}>
                                        <img src={this.props.data.umbraco.AboutImage}/>
                                    </div>
                                    : ""}
                                {(<div className={"AboutText"} dangerouslySetInnerHTML={{__html: this.props.data.umbraco ? this.props.data.umbraco.MenuDescriptionNewVersion[serverLang]:""}}/>)}
                                <ul className={"ExternalLinks"}>
                                    {externalLinks}
                                </ul>
                            </div>
                            : ""}
                    </div>

                </div>
                {this.props.swapped ? <SwapButton text={"Guides"} swap={this.props.swap}/>:""}
            </div>
        </div>;
    }

};

export default AppInstance;
