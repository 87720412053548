import React, {Component} from 'react';
import './SwapButton.scss';
import {ReactComponent as GmapsIcon} from '../../assets/Icons/gmaps.svg';

class SwapButton extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={"SwapButton "+this.props.text} onClick={this.props.swap}>
                <GmapsIcon className={"gmapsIcon"}/>
                <div className="mapSwapBtnText">{this.props.text}</div>
            </div>
        );
    }
}


export default SwapButton;
