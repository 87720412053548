import React, {Component} from 'react';
import './LeftListMenu.scss';
import {IntlProvider} from "react-intl";
import {ReactComponent as Rewind15Icon} from '../../assets/Icons/rewind15.svg';
import {ReactComponent as PreviousIcon} from '../../assets/Icons/previous.svg';
import {ReactComponent as PlayIcon} from '../../assets/Icons/play.svg';
import {ReactComponent as PauseIcon} from '../../assets/Icons/pause2.svg';
import {ReactComponent as NextIcon} from '../../assets/Icons/next.svg';
import {ReactComponent as Wind15Icon} from '../../assets/Icons/wind15.svg';
import ReactGA from "react-ga";

const TAG = "LeftListMenu";
class LeftListMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: 0,
            currentTime: 0,
            percentageDone: 0,
            paused: false,
            pausedtest: false,
            playbackrate: 1,
            muted: false
        }

        this.progress = React.createRef();
        this.progressClicker = React.createRef();
    }

    componentDidMount() {
        //console.log("LeftListMenu mounted");
        //console.log(this.props);
    }

    componentDidUpdate(prevProps){
        if(prevProps.musicPlayer === null && this.props.musicPlayer !== null) {
            // Musicplayer have now been set
            if(this.props.musicPlayer.current) {
                this.props.musicPlayer.current.addEventListener('loadedmetadata', this.loadedMetadata.bind(this))
            }
        }
        else if(prevProps.musicPlayer.current === null && this.props.musicPlayer.current !== null) {
            this.props.musicPlayer.current.addEventListener('loadedmetadata', this.loadedMetadata.bind(this))
        }
    }

    nextLocation() {
        this.setState({paused: false}, null);
        this.props.nextLocation();
    }
    gotNext() {
        return !this.props.isLast;
    }
    previousLocation() {
        this.setState({paused: false}, null);
        this.props.previousLocation();
    }
    gotPrevious() {
        return !this.props.isFirst;
    }
    play() {
        console.log(TAG, "Playing");
        this.props.musicPlayer.current.play();
        this.setState({pausedtest: false});
        /*ReactGA.event({
            category: 'Audio',
            action: 'AudioPlay'
        });*/
    }
    pause() {
        console.log(TAG, "Pausing Audio");
        this.props.musicPlayer.current.pause();
        this.setState({pausedtest: true});
        /*ReactGA.event({
            category: 'Audio',
            action: 'AudioPaused'
        });*/
    }
    stop() {
        ReactGA.event({
            category: 'Audio',
            action: 'AudioStopped'
        });
    }
    mute() {

    }
    prev15() {
        let audio = this.props.musicPlayer.current;
        let current = audio.currentTime;
        let prev15 = current-15;
        if(prev15 < 0) {
            prev15 = 0;
        }
        audio.currentTime = prev15;
    }
    next15() {
        let audio = this.props.musicPlayer.current;
        let current = audio.currentTime;
        let next15 = current+15;
        if(next15 > audio.duration) {
            next15 = audio.duration-1;
        }
        audio.currentTime = next15;
    }
    setTime(percentage) {
        console.log(TAG, "setTime");
        let audio = this.props.musicPlayer.current;
        console.log(TAG, "Audio Tag: ", audio);
        let max = audio.duration;
        audio.currentTime = max * (percentage/100);
    }
    reset() {
        this.setTime(0);
    }
    updateTime() {
        //console.log("Update time");
        //console.log(this.state);
        let audio = this.props.musicPlayer.current;
        //console.log(audio);
        let newCurrentTime = Math.floor(audio.currentTime);
        let newPercentage = Math.floor((audio.currentTime/audio.duration)*100);
        //console.log(newCurrentTime,newPercentage, audio.currentTime, audio.duration);
        this.setState({currentTime: newCurrentTime, percentageDone: newPercentage},null);
    }
    getTime(duration) {
        //console.log("Duration: ",duration);
        let hours = parseInt( duration / 3600 ) % 24;
        let minutes = parseInt( duration / 60 ) % 60;
        let seconds = duration % 60;
        //console.log("Hours: ", hours, " Minutes: ", minutes, " Seconds: ",seconds);

        //return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds  < 10 ? "0" + seconds : seconds);
        return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds  < 10 ? "0" + seconds : seconds);
        //console.log(result);
        //return result;
    }
    isPlaying() {
        console.log(TAG, "Is Music Playing? ", this.props.musicPlayer, "Current Pause: ", this.props.musicPlayer && this.props.musicPlayer.current ? this.props.musicPlayer.current : null);
        if(this.props.musicPlayer && this.props.musicPlayer.current) {
            return !this.props.musicPlayer.current.paused;
        }
        else {
            return false
        }
    }
    gotAudio() {
        return !!this.state.duration;
    }
    loadedMetadata() {
        console.log(TAG, "Loaded Metadata");
        let audio = this.props.musicPlayer.current;
        this.setState({duration: Math.floor(audio.duration)}, function() {
            audio.addEventListener('timeupdate', this.updateTime.bind(this));
        });

        //audio.addEventListener('ontimeupdate', this.updateTime);

        //audio.ontimeupdate = this.updateTime;
    }
    progressClick(e) {
        console.log(TAG, "PROGRESS CLICK");
        let progress = this.progress.current;
        let progressClicker = this.progressClicker.current;
        let rect = progressClicker.getBoundingClientRect(),
        x = e.pageX - (rect.left-5), // this.offsetLeft or e.offsetX (less support, though)
        //y = e.pageY - this.offsetTop,  // or e.offsetY
        clickedValue = Math.floor(x * progress.max / progressClicker.offsetWidth);

        //console.log("Max Value: ", progress.max);
        //console.log("Value: ", clickedValue);
        //console.log(rect.top, rect.right, rect.bottom, rect.left);
        //console.log(e.pageX, progressClicker.offsetLeft, x, progress.max, progressClicker.offsetWidth, clickedValue);
        this.setTime(clickedValue);
    }

    render() {
        //console.log("LeftList Music Player Ref: ", this.props.musicPlayer);
        return (
            <div className={"LeftListMenu Black"}>
                <div className={"Gridcontainer"}>
                    <div className={"progressTime" + (!this.gotAudio() ? " deactivated":"")}>{this.getTime(this.state.currentTime)}</div>
                    <div className={"progressBar" + (!this.gotAudio() ? " deactivated":"")}>
                        <progress ref={this.progress} max={"100"} value={this.state.percentageDone}/>
                    </div>
                    <div className={"progressClicker"} ref={this.progressClicker} onClick={this.progressClick.bind(this)}/>
                    <div className={"progressTotal" + (!this.gotAudio() ? " deactivated":"")}>{this.getTime(this.state.duration)}</div>
                    <div className={"title"}>{this.props.title}</div>
                    <div className={"back ico small grey"} onClick={this.previousLocation.bind(this)}>
                        <PreviousIcon className={(!this.gotPrevious() ? "deactivated":"")}/>
                    </div>
                    <div className={"rewind ico small"}>
                        <Rewind15Icon className={(!this.gotAudio() ? "deactivated":"")} onClick={this.prev15.bind(this)}/>
                    </div>
                    <div className={"audioIcon ico"}>
                        {this.isPlaying() ?
                            <PauseIcon className={(!this.gotAudio() ? "deactivated":"")} onClick={this.pause.bind(this)}/> :
                            <PlayIcon className={(!this.gotAudio() ? "deactivated":"")} onClick={this.play.bind(this)}/>
                        }

                    </div>
                    <div className={"wind ico small"}>
                        <Wind15Icon className={(!this.gotAudio() ? "deactivated":"")} onClick={this.next15.bind(this)}/>
                    </div>
                    <div className={"next ico small"} onClick={this.nextLocation.bind(this)}>
                        <NextIcon className={(!this.gotNext() ? "deactivated":"")}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftListMenu;
