import React, {Component} from 'react';
import './LeftList.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";
import ImagePlayer from "../../components/ImagePlayer/ImagePlayer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import {ReactComponent as BackArrowIcon} from '../../assets/Icons/play4.svg';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";

class LeftList extends Component {
    constructor(props) {
        super(props);
        this.musicPlayer = React.createRef();
        this.props.setMusicPlayer(this.musicPlayer);
    }

    componentDidMount() {
        /* This calls a function that moves the Leaflet copyright box to the left when Leftlist is displayed */
        this.props.moveLeft(true);
        console.log("LeftList Did Mount");
        ReactGA.pageview('WalkLocation');
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
    }
    componentWillUnmount() {
        /* And this moves it back to the right when LeftList disappears */
        this.props.moveLeft(false);
    }

    render() {
        let locationData = this.props.locationData;
        //console.log("LeftListLocationData:",locationData);
        return (
            <div className={"LeftList"}>
                <Helmet>
                    <title>{locationData.Name}</title>
                    {(locationData.Images && locationData.Images.length > 0 ? <meta property="og:image" content={locationData.Images[0]}/> : "")}
                    <meta property="og:description" content={locationData.Text}/>
                    <meta property="og:title" content={locationData.Name}/>
                </Helmet>

                <div className="Headr">
                    <div className={"BackBtn"}><NavLink to={"../"}><BackArrowIcon className={"backarrow"}/>Back</NavLink></div>
                    <div className={"Title"}>{locationData.Name}</div>
                </div>
                {locationData.Video ? <VideoPlayer video={locationData.Video} videoPoster={locationData.VideoThumbnail}/> : ""}
                <ImagePlayer
                    images={locationData.Images}
                    captions={locationData.ImageCaptions || []}
                    openFullscreen={this.props.openFullscreen}
                />
                {(locationData.Text.indexOf("http") !== 0 ? <div className={"Text"} dangerouslySetInnerHTML={{__html: locationData.Text}}/> : <div className={"text"}/>)}
                {(locationData.Sound ? <audio ref={this.musicPlayer} src={locationData.Sound+"?sv=2015-04-05"}/> :"")}
            </div>
        );
    }
}


export default LeftList;
