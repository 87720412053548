import React, {Component} from 'react';
import { renderRoutes } from "react-router-config";
import Header from '../components/Header/Header';
//import Footer from '../components/Footer/Footer';
import LanguageSelect from '../pages/LanguageSelect/LanguageSelect';
import {matchPath, Switch} from "react-router";
import Routes from "./routes";
import {loadData} from "../providers/data-manager/loadData";
import './Root.scss';
import SearchResult from "../pages/SearchResult/SearchResult";


let Map;

const TAG = "Root";
class Root extends Component {

    constructor(props) {
        super(props);
        //console.log("ROOT CONSTRUCTOR");
        //console.log(props);
        let logoWide = "/OSMS_logga_utanmost.png";
        let logoSmol = "/OSMS_logga2.png";
        if (props.webappData && props.webappData.WebAppConfig) {
            let wac = props.webappData.WebAppConfig;
            if (wac.HeaderIcon) {
                logoWide = wac.HeaderIcon;
            }
            if(wac.HeaderIconMobile) {
                logoSmol = wac.HeaderIconMobile;
            } else if (!wac.HeaderIconMobile && wac.HeaderIcon) {
                logoSmol = wac.HeaderIcon;
            }
        }
        this.state = {
            data: props.data,
            webappData: props.webappData,
            mobile: true,
            openLanguageSelect: false,
            showMap: false,
            params: {},
            swapScreen: true,
            moveLeft: false,
            logoWide: logoWide,
            logoSmol: logoSmol,
            searchResult: [],
            showSearchResult: false,
            overSearch: false,
            innerHeight: null,
            languages: [],
            walk: null
        }
        //console.log("WEBAPPDATA:",this.state.webappData);


        console.log("Serverside Rendered: ", props.data.ssr);
        this.routeData = this.getCurrentRoute();
    }


    componentDidMount() {
        //console.log("Root did mount");
        let MapThing = require('../pages/Map/MapThing');
        //console.log(MapThing);
        Map = MapThing.Map;
        //console.log(Map);
        let data = this.state.data;
        //data.ssr = false;
        this.setState({ showMap: true})
        if(!this.props.data.ssr) {
            window.onresize = function() {
                //console.log("Resized! ", window.innerHeight);
                window.setTimeout(this.setState.bind(this, {innerHeight: window.innerHeight}), 200);
            }.bind(this)
            //this.state.innerHeight = window.innerHeight;
            let newState = {innerHeight: window.innerHeight};
            const routeData = this.getCurrentRoute();
            if(!routeData.params.language && routeData.params.appid) {
                newState.openLanguageSelect = true;
            }
            this.setState(newState);
            //console.log("Setting innerHeight to window",  window.innerHeight);
        }
        //this.swap()
    }

    componentDidUpdate(prevProps, prevState) {
        this.routeData = this.getCurrentRoute();
        if(this.routeData) {
            console.log("ROOT", "Updated", this.routeData.params.appid, this.routeData.params.language, this.state.openLanguageSelect, this.state.languages)
            if(this.routeData.params.appid && !this.routeData.params.language && !this.state.openLanguageSelect && this.state.languages.length > 1) {
                this.setState({openLanguageSelect: true},null);
            } else if (!this.routeData.params.appid && this.state.openLanguageSelect){
                this.setState({openLanguageSelect: false},null);
            }
        }
    }

    toggleLanguageSelect() {
        this.setState({openLanguageSelect: !this.state.openLanguageSelect},null);
    }

    setActiveMarker(i) {
        this.setState({activeMarker: i},null);
    }

    getCurrentRoute() {
        if(this.state.data.ssr) { return; }
        const currentRoute = Routes[0].routes.find(route => matchPath(window.location.pathname, route)) || {};
        const routeData = matchPath(window.location.pathname, currentRoute);
        //this.setState({params: routeData.params},null);
        return routeData;
    }

    downloadUmbracoData() {
        //console.log("We should rly download Umbraco data...", window.location.pathname);
        //const currentRoute = Routes[0].routes.find(route => matchPath(window.location.pathname, route)) || {};
        //const routeData = matchPath(window.location.pathname, currentRoute);
        const routeData = this.getCurrentRoute();
        //console.log("currentRoute:",routeData);
        //console.log(this.props.match);
        return loadData(routeData.params.appid, routeData.params.language, routeData.params.walkid, this.state.data).then(data => {
            //console.log("DATA: ",data.language);´
            this.setState({
                data
            }, this.makeLanguageList);
            return data;
        });
    }

    swap() {
        this.setState({swapScreen: !this.state.swapScreen},null);
    }

    moveLeft(moveLeft) {
        /* This function only exists to move the Leaflet copyright box to the left when LeftList is opened */
        this.setState({moveLeft: moveLeft},null);
    }
    setSearchResult(result) {
        this.setState({searchResult: result ? result : []}) //, showSearchResult: (result && result.length > 0)
        console.log("Setting results: ", result, (result && result.length > 0));
    }
    showSearchResult(value) {
        this.setState({showSearchResult: (value && this.state.searchResult.length > 0)})
    }
    setOverSearch(value) {
        this.setState({overSearch: value});
    }
    toggleSearchResult() {
        //console.log("Toggle Search Result", this.state.overSearch, this.state.showSearchResult);
        if(!this.state.overSearch && this.state.showSearchResult && !this.state.mobile) {
            this.setState({showSearchResult:  false})
        }
        else if(this.state.overSearch && !this.state.showSearchResult && this.state.searchResult.length > 0) {
            this.setState({showSearchResult:  true})
        }
        else if(this.state.overSearch && this.state.mobile && !this.state.showSearchResult) {
            this.setState({showSearchResult:  true})
        }
        else if(this.state.overSearch && this.state.mobile && this.state.showSearchResult) {
            this.setState({showSearchResult:  false})
        }
    }

    makeLanguageList() {
        console.log(TAG, "Making Language List");
        let languages = [];
        let default_lang = "";
        let walk;
        //let routeData = this.getCurrentRoute();
        const routeData = this.routeData;
        console.log(TAG, !!this.state.data,!!this.state.data.umbraco)
        if(this.state.data && this.state.data.umbraco) {
            console.log(TAG, "Got WalkID? ", routeData.params.walkid);
            for(let w in this.state.data.umbraco.Walks) {
                //console.log("WALK: ",w,this.props.data.umbraco.Walks[w].Id,this.props.match.params.walkid);
                if(this.state.data.umbraco.Walks.hasOwnProperty(w) /*&& this.state.data.umbraco.Walks[w].Id === parseInt(routeData.params.walkid)*/) {
                    walk = this.state.data.umbraco.Walks[w];

                    /* If a specific walk is selected in the URL, limit the language list to what this walk has */
                    if(routeData.params.walkid && walk.Id === parseInt(routeData.params.walkid)) {
                        languages = walk.Languages;
                        default_lang = walk.DefaultLanguage;
                        break;
                    }

                    /* Otherwise sum up all the languages all walks support. After a language is selected, the walks supporting that language will be shown */
                    //console.log(TAG, "Concating in these languages: ", walk.Languages);
                    languages = [...new Set([...languages,...walk.Languages])]
                    //console.log(TAG, "New LanguageList: ", languages);

                    //console.log(languages,default_lang);
                }
            }
            console.log(languages,default_lang);
            this.setState({languages, default_lang, walk});
        }
    }

    render() {
        //console.log(this.props);
        //console.log("Data: ", this.state.data);
        //console.log("Features: ", this.state.data.umbraco.Features);
        console.log("SWAPPED? ", this.state.swapScreen);
        let markers = [];
        let mapImage = null;
        let mapImageBounds = null;
        let firebase_app = null;
        let autoCenter = false;
        let centerPos = [];
        let initZoom = 0;
        //console.log(this.props.match);

        //let routeData = this.getCurrentRoute();
        const routeData = this.routeData;
        console.log("Routedata: ", routeData);
        if(routeData && routeData.params && routeData.params.appid && this.state.data.firebase[routeData.params.appid]) {
            //console.log("Routedata in render: ", routeData);
            firebase_app = this.state.data.firebase[routeData.params.appid];
            if(firebase_app) {
                //console.log("Firebase APP: ", firebase_app);
                //console.log(this.state.data.firebase[this.props.match.params.appid] ? this.props.data.firebase[this.props.match.params.appid] : "");


                /*firebase_app.APP_LANGUAGE.supported = (typeof firebase_app.APP_LANGUAGE.supported === "object" ? firebase_app.APP_LANGUAGE.supported : JSON.parse(firebase_app.APP_LANGUAGE.supported));
                /*if(this.state.data.firebase) {
                    for(let l in firebase_app.APP_LANGUAGE.supported) {
                        if(firebase_app.APP_LANGUAGE.supported.hasOwnProperty(l)) {
                            let lang = firebase_app.APP_LANGUAGE.supported[l];
                            //languages.push(<li key={"lang_"+l} style={{fontWeight: (firebase_app.APP_LANGUAGE.default === lang ? "bold": "normal")}}><NavLink to={(this.props.match.params.language ? "../"+lang+"/" : lang+"/")}>{lang}</NavLink></li>)
                            languages.push(lang);
                            if(firebase_app.APP_LANGUAGE.default === lang) {
                                default_lang = lang;
                            }
                        }
                    }
                }*/
            }

            // Get current walk


            /* Don't do anything for the map if this is serverside rendered, since the server can't run Leaflet anyways */
            if(!this.state.data.ssr) {
                markers = [];
                /* Prepare a list of mapmarkers */
                if(routeData.params.appid && !routeData.params.walkid) {
                    /* AppInstance
                    * Render all Walks on map */
                    if(this.state.data && this.state.data.umbraco) {
                        autoCenter = this.state.data.umbraco.AutoCenter;
                        centerPos = [this.state.data.umbraco.CenterLat, this.state.data.umbraco.CenterLon];
                        let walk;
                        for(let w in this.state.data.umbraco.Walks) {
                            if(this.state.data.umbraco.Walks.hasOwnProperty(w)) {
                                walk = this.state.data.umbraco.Walks[w];
                                if(walk.CenterLat && walk.CenterLon) {
                                    let marker = {
                                        lat: walk.CenterLat,
                                        lng: walk.CenterLon,
                                        title: walk.NameNewVersion[routeData.params.language],
                                        imgurl: walk.DescriptionImages.length > 0 ? walk.DescriptionImages[0] : "",
                                        id: walk.Id
                                    }
                                    //,link: (routeData.params.walkid ? "../"+walk.Id+"/" : walk.Id+"/")
                                    //console.log("Adding ",marker);
                                    markers.push(marker);
                                }
                            }
                        }
                        let d = this.state.data.umbraco;
                        if(d.MapImage && this.hasBounds(d)) {
                            /* This instance has an non-language specific mapimage */
                            mapImage = d.MapImage;
                            mapImageBounds = [
                                [d.TopRightLat, d.TopRightLon],
                                [d.BottomLeftLat, d.BottomLeftLon]
                            ]
                        }
                    }

                } else if (routeData.params.appid && routeData.params.language && routeData.params.walkid) {
                    // Walk
                    /* Render all Locations on map */
                    if(this.state.walk) {
                        console.log(TAG, "Current Walk: ", this.state.walk);
                        autoCenter = this.state.walk.AutoCenter;
                        centerPos = [this.state.walk.CenterLat, this.state.walk.CenterLon];
                        initZoom = this.state.walk.InitZoom;
                        for(let l in this.state.walk.Locations) {
                            if(this.state.walk.Locations.hasOwnProperty(l)) {
                                let location = this.state.walk.Locations[l];
                                if(location.Latitude && location.Longitude) {

                                    let marker = {
                                        lat: location.Latitude,
                                        lng: location.Longitude,
                                        title: location.Name,
                                        imgurl: location.Images.length > 0 ? location.Images[0] : "",
                                        id: location.Id
                                    };
                                    //,link: (routeData.params.location ? "../"+location.Id+"/" : location.Id+"/")
                                    //console.log("New marker: ",marker);
                                    markers.push(marker);
                                }
                            }
                        }
                        let d = this.state.walk;
                        //console.log("Walk MapImage: ", d.MapImage, this.hasBounds(d));
                        if(d.MapImage && this.hasBounds(d)) {

                            /* This Walk has an non-language specific mapimage */
                            mapImage = d.MapImage;
                            mapImageBounds = [
                                [d.TopRightLat, d.TopRightLon],
                                [d.BottomLeftLat, d.BottomLeftLon]
                            ]
                        }
                    }
                }
            }
        }

        //console.log("ServerLanguage: ",this.state.data.language);



        //let access_token, titleLayerUrl, mapBoxUser;
        let map_opt = {};
        if(this.state.data.firebase && this.state.data.firebase['default']['APP_SETTINGS'] && this.state.data.firebase['default']['APP_SETTINGS']['MAP_MapBoxAccessToken']) {

            map_opt = {
                titleLayerUrl: "https://api.mapbox.com/styles/v1/{id}/" +
                    this.state.data.firebase['default']['APP_SETTINGS']['MAP_MapCustomUrl'] +
                    "/tiles/256/{z}/{x}/{y}?access_token={access_token}",
                access_token: this.state.data.firebase['default']['APP_SETTINGS']['MAP_MapBoxAccessToken'],
                mapBoxUser: this.state.data.firebase['default']['APP_SETTINGS']['MAP_MapBoxUser']
            }

            //console.log("Default Access token: ", access_token);
            //console.log("Default URL: ", titleLayerUrl);
            //console.log("Default MapBoxUser: ", mapBoxUser);
            //console.log("Firebase App: ", firebase_app);
            if(firebase_app) {
                /*access_token = firebase_app['APP_SETTINGS']['MAP_MapBoxAccessToken'];
                titleLayerUrl =
                    "https://api.mapbox.com/styles/v1/{id}/" +
                    firebase_app['APP_SETTINGS']['MAP_MapCustomUrl'] +
                    "/tiles/256/{z}/{x}/{y}?access_token={access_token}";
                mapBoxUser = firebase_app['APP_SETTINGS']['MAP_MapBoxUser'];*/
                let app_map_opt = {}
                const app_settings = firebase_app['APP_SETTINGS'];
                if(typeof app_settings['MAP_MapCustomUrl'] !== 'undefined') app_map_opt.titleLayerUrl = "https://api.mapbox.com/styles/v1/{id}/" + firebase_app['APP_SETTINGS']['MAP_MapCustomUrl'] + "/tiles/256/{z}/{x}/{y}?access_token={access_token}";
                if(typeof app_settings['MAP_MapBoxAccessToken'] !== 'undefined') app_map_opt.access_token = firebase_app['APP_SETTINGS']['MAP_MapBoxAccessToken'];
                if(typeof app_settings['MAP_MapBoxUser'] !== 'undefined') app_map_opt.mapBoxUser = firebase_app['APP_SETTINGS']['MAP_MapBoxUser'];

                /*console.log("Specific app access token: ", app_map_opt.access_token);
                console.log("Specific app URL: ", app_map_opt.titleLayerUrl);
                console.log("Specific App MapBoxUser: ", app_map_opt.mapBoxUser);*/
                console.log(TAG, {app_map_opt});
                map_opt = Object.assign(map_opt, app_map_opt);
            }
            console.log(TAG, {map_opt});
        }
        //console.log("Specific App MapBoxUser: ", mapBoxUser);
        //console.log("SHOW SEARCH RESULT?", this.state.showSearchResult);
        //console.log("HEIGHT: ", this.state.innerHeight, "Setting: ", this.state.innerHeight+"px");
        //console.log("WebappData Root: ", this.state.webappData);

        let appIdForSearch =
            routeData &&
            routeData.params.appid &&
            this.state.data.firebase &&
            this.state.data.firebase[routeData.params.appid] &&
            this.state.data.firebase[routeData.params.appid].APP_SETTINGS
                ? this.state.data.firebase[routeData.params.appid].APP_SETTINGS.CMS_GUIDE_NUMBER : null;
        return (
            <div className={"Root"} onClick={this.toggleSearchResult.bind(this)} style={this.state.innerHeight ? {height: this.state.innerHeight+"px"}:{}}>
                <Header
                    toggleLanguageSelect={this.toggleLanguageSelect.bind(this)}
                    routeData={routeData}
                    languages={this.state.languages}
                    logoWide={this.state.logoWide}
                    logoSmol={this.state.logoSmol}
                    webappData={this.state.webappData}
                    appId={appIdForSearch}
                    setSearchResult={this.setSearchResult.bind(this)}
                    showSearchResult={this.showSearchResult.bind(this)}
                    setOverSearch={this.setOverSearch.bind(this)}
                    features={this.state.data.umbraco ? this.state.data.umbraco.Features.Webapp : { Search: false}}
                />

                <div className={"Content"+(this.state.swapScreen ? " Swapped":"")}>
                    {this.state.showMap ? (
                        <Map
                            markers={markers}
                            moveLeft={this.state.moveLeft}
                            activeMarker={routeData.params.location}
                            history={this.props.history}
                            routeData={routeData}
                            swap={this.swap.bind(this)}
                            swapped={this.state.swapScreen}
                            mapImage={mapImage}
                            mapImageBounds={mapImageBounds}
                            autoCenter={autoCenter}
                            centerPos={centerPos}
                            initZoom={initZoom}
                            access_token={map_opt.access_token}
                            tileLayerUrl={map_opt.titleLayerUrl}
                            mapBoxUser={map_opt.mapBoxUser}
                        />
                    ) : <div>Map requires javascript enabled</div>}


                    <Switch>
                        {renderRoutes(this.props.route.routes, {
                            data: this.state.data,
                            webappData: this.state.webappData,
                            downloadUmbracoData:this.downloadUmbracoData.bind(this),
                            toggleLanguageSelect:this.toggleLanguageSelect.bind(this),
                            makeLanguageList:this.makeLanguageList.bind(this),
                            swap:this.swap.bind(this),
                            swapped:this.state.swapScreen,
                            moveLeft:this.moveLeft.bind(this),
                            searchResult:this.state.searchResult,
                            showSearchResult: this.state.showSearchResult,
                            routeData:routeData
                        })}
                    </Switch>

                    {this.state.showSearchResult ? <SearchResult
                        routeData={routeData}
                        searchResult={this.state.searchResult}
                        params={routeData.params}
                        appId={appIdForSearch}
                        setOverSearch={this.setOverSearch.bind(this)}
                        setSearchResult={this.setSearchResult.bind(this)}
                        showSearchResult={this.showSearchResult.bind(this)}
                        mobile={this.state.mobile}
                    />:""}
                </div>

                {/* <Footer/> */}
                {(this.state.openLanguageSelect ? <LanguageSelect
                    appid={routeData.params.appid}
                    currentLang={routeData.params.language}
                    walkid={routeData.params.walkid}
                    location={routeData.params.location}
                    languages={this.state.languages}
                    default_lang={this.state.default_lang}
                    toggleLanguageSelect={this.toggleLanguageSelect.bind(this)}
                /> : "")}
            </div>
        );
    }

    hasBounds(d) {
        return(d.TopRightLat && d.TopRightLon && d.BottomLeftLat && d.BottomLeftLon)
    }
}


export default Root;

/*const Root = ({ route }) => (
    <div>
        <h1>Root</h1>
        {renderRoutes(route.routes)}
    </div>
);
export default Root;*/
