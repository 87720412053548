import React, {Component} from 'react';
import './Fullscreen.scss';
import {IntlProvider} from "react-intl";
import {NavLink} from "react-router-dom";
import ImagePlayer from "../../components/ImagePlayer/ImagePlayer";
import {ReactComponent as TinyscreenIcon} from '../../assets/Icons/tiny_screen.svg';
import {ReactComponent as LeftIcon} from '../../assets/Icons/left.svg';
import {ReactComponent as RightIcon} from '../../assets/Icons/right.svg';
import ReactGA from "react-ga";

class Fullscreen extends Component {
    constructor(props) {
        super(props);
        this.state ={
            current: this.props.index
        }
        console.log("props: ", this.props);
    }

    componentDidMount() {
        ReactGA.pageview('FullscreenMedia');
        var _paq = window._paq = window._paq || [];
        _paq.push(['trackPageView']);
    }

    goLeft() {
        console.log("Left", this.state.current);
        if(this.state.current > 0) {
            this.setState({current:this.state.current-1});
        }
        //this.props.goLeft();
    }
    goRight() {
        console.log("Right", this.state.current, this.props.media.length);
        if(this.state.current < this.props.media.length -1) {
            this.setState({current: this.state.current+1});
        }
        //this.props.goRight();
    }

    render() {
        if(this.props.media && this.props.media.length > 0) {
            let url = this.props.media[this.state.current].replace(/devapps/, "developerapps")
            //console.log("ImageURL: ",url);
            return (
                <div className={"Fullscreen"} style={{backgroundImage: "url("+url+"?no_compression)"}}>
                    {this.state.current > 0 ? <div className="previous" onClick={this.goLeft.bind(this)}><LeftIcon/></div>:""}
                    {this.state.current < this.props.media.length -1 ? <div className="next" onClick={this.goRight.bind(this)}><RightIcon/></div> : ""}
                    <TinyscreenIcon className="tinyscreen" onClick={this.props.closeFullscreen}/>

                    {this.props.caption && [this.state.current].caption ? <div className="caption">{this.props.captions[this.state.current].caption}</div> : ""}
                </div>
            );
        }
        return "";
    }
}


export default Fullscreen;
